import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addBlog,
  getBlogs,
  getBlog,
  editBlog,
  deleteBlog,
  getAllBlogs,
  getBlogBySlug,
} from "../../store/actions/blog_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllBlogs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.blog);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteBlog(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {

      dispatch(
        getBlogs({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data, setPageNumber, deleteBtnClicked
  ];
};

// Get Single Data
export const useSingleBlog = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.blog);
  useEffect(() => {
    dispatch(getBlog(id));
  }, [id]);
  return [data];
};

//Get Single Data by Slug
export const useSingleBlogBySlug = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.blog);
  useEffect(() => {
    dispatch(getBlogBySlug(id));
  }, [id]);
  return [data];
};

// Add Data
export const useCreateBlog = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.blog);
  const addData = async (data) => {
    await dispatch(addBlog(data));
  };
  return [data, addData];
};
export const useUpdateBlog = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.blog);
  const updateData = async (id, data) => {
    await dispatch(editBlog(id, data));
  };
  return [updateData];
};

export const useSelectAllBlog = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.blog);
  useEffect(() => {
    dispatch(getAllBlogs({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};


export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };


  return [dropdownOptions, loadOptions];
};