import React, { useState } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import Lightbox from "react-awesome-lightbox";
import { CertificateData } from '../../models/certificateData';
import { MENU_TABS } from '../../domain/constant'



function Certificates() {

    const [certificate, setCertificate] = useState(CertificateData)

    let subtitle;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const [activeImage, setActiveImage] = useState(null)
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(image) {
        setActiveImage(image)
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            <Header activeTab={MENU_TABS.CERTIFICATE} />
            <Breadcrumb title={`Certificates`} />
            <section className="certificate ptb-60">
                <div className="container">
                    <div className="row">
                        {
                            certificate && certificate.map((item) => {
                                return (
                                    <div className="col-md-4">
                                        <div className="certificate-card">
                                            <div className="certificate-card-img">
                                                <img onClick={() => openModal(item.image)} src={item.image} alt="" />
                                            </div>
                                            <div className="certificate-card-title">
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {activeImage && (
                        <Lightbox image={activeImage} onClose={() => setActiveImage(null)} >
                        </Lightbox>
                    )}
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Certificates