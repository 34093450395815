export const ServiceData = [
    {
        image: `/assets/images/services/20212.jpg`,
        title: "COMFORT AIRCONDITIONING",
        short_description: "Comfort Airconditioning applies for the places where Airconditioning <br /> is required for comfort and Luxuries.",
        description: "Comfort Airconditioning applies for the places where Airconditioning <br /> is required for comfort and Luxuries.",
    },
    {
        image: `/assets/images/services/20212.jpg`,
        title: "PRECISION AIR CONDITIONING",
        short_description: "Precision Air conditioning applies to the places where <br /> accurate conditions are required to carry out the process.",
        description: "Precision Air conditioning applies to the places where <br /> accurate conditions are required to carry out the process",
    },

]