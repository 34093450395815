export const LIMIT = 10;
export const UPLOAD_CONST = "";
export const URI = process.env.REACT_APP_URI;

export const MENU_TABS = {
    HOME: "HOME",
    ABOUT: "ABOUT",
    SERVICE: "SERVICE",
    PROJECT: "PROJECT",
    CERTIFICATE: "CERTIFICATE",
    CONTACT_US: "CONTACT_US",
}
