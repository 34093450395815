import React from 'react'

function Contactus() {
    return (
        <div>
            <section className="appoinment-area" style={{ backgroundImage: 'url(/assets/images/resources/appoinment-bg.png)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                            <div className="img-holder">
                                <img src="/assets/images/resources/form_new.jpg" alt="Awesome Image" />
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                            <div className="appoinment">
                                <div className="sec-title">
                                    <h1><span>Make an Appoinment</span></h1>
                                    <p>
                                        Just fill out the following form, our expert team will contact
                                        and make sure you<br />
                                        get the highest level of help.
                                    </p>
                                </div>
                                <form className="appoinment-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="field-label">Name*</div>
                                            <div className="field-input">
                                                <input type="text" name="form_name" defaultValue placeholder required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="field-label">Phone Number</div>
                                            <div className="field-input">
                                                <input type="text" name="form_phnnumber" defaultValue placeholder required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="field-label">Address</div>
                                            <div className="field-input">
                                                <input type="text" name="address" defaultValue placeholder />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="field-label">Select Service</div>
                                            <select className="selectmenu">
                                                <option selected="selected">Maintenance &amp; Repair</option>
                                                <option>A/C Installation</option>
                                                <option>Diagnostics</option>
                                                <option>Heating and Water</option>
                                                <option>Energy Efficiency</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button className="#" type="submit">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contactus