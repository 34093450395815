import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { MENU_TABS } from '../../domain/constant'


function ProjectTable() {
    return (
        <div>
            <Header activeTab={MENU_TABS.PROJECT} />
            <Breadcrumb title={`Project Table`} />
            <div className="project-table-sec">
                <div className="container">
                    <div className="table-responsive">
                        <table className="table table-bordered custom-table">
                            <caption className="table-caption">Hospitals</caption>
                            <thead>
                                <tr>
                                    <th className="table-header" scope="col">Project Name</th>
                                    <th className="table-header" scope="col">Location</th>
                                    <th className="table-header" scope="col">Completion Date</th>
                                    <th className="table-header" scope="col">Architect Consultant</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mata Amrita Devi Hospital</td>
                                    <td>Faridabad</td>
                                    <td>Running</td>
                                    <td>Dew Point Services LLP</td>
                                </tr>
                                <tr>
                                    <td>Paras Hospital</td>
                                    <td>Ranchi</td>
                                    <td>2018</td>
                                    <td>Acrobat Engg. Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td> Regency Renal Science Centre</td>
                                    <td>Lucknow</td>
                                    <td>2018</td>
                                    <td>Acrobat Engg. Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td> Regency Hospital</td>
                                    <td>Kanpur</td>
                                    <td>2018</td>
                                    <td>Acrobat Engg. Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td> Regency Hospital</td>
                                    <td>Kanpur</td>
                                    <td>2018</td>
                                    <td>Acrobat Engg. Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td> HMEL</td>
                                    <td>Noida</td>
                                    <td>2018</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>IVF Centre – Saket</td>
                                    <td>Delhi</td>
                                    <td>2017</td>
                                    <td>ACE</td>
                                </tr>
                                <tr>
                                    <td>Ksheer Sagar -Factory</td>
                                    <td>Noida</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consultant</td>
                                </tr>
                                <tr>
                                    <td>Febris Hospital – Narela</td>
                                    <td>Delhi</td>
                                    <td>2017</td>
                                    <td>Creative Designer</td>
                                </tr>
                                <tr>
                                    <td>ACCIL Factory</td>
                                    <td>Bawal</td>
                                    <td>2015</td>
                                    <td>FAS</td>
                                </tr>
                                <tr>
                                    <td>Yashoda Hospital</td>
                                    <td>Kaushambi Ghaziabad</td>
                                    <td>2014</td>
                                    <td>Creative Designer</td>
                                </tr>
                                <tr>
                                    <td>Urgent Care Hospital – Preet Vihar</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>Vertex Consultants</td>
                                </tr>
                                <tr>
                                    <td>Urgent Care Hospital – Nehru Place</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>Vertex Consultants</td>
                                </tr>
                                <tr>
                                    <td>Urgent Care Hospital – Jasola</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>Vertex Consultants</td>
                                </tr>
                                <tr>
                                    <td>Gupta Exim Factory</td>
                                    <td>Prithla</td>
                                    <td>2013</td>
                                    <td>Abid Hussain Consultant</td>
                                </tr>
                                <tr>
                                    <td>Sir Ganga Ram Hospital</td>
                                    <td>Delhi</td>
                                    <td>2012</td>
                                    <td>Sterling India</td>
                                </tr>
                                <tr>
                                    <td>Christian Medical College &amp; Hospital</td>
                                    <td>Ludhiana</td>
                                    <td>2011</td>
                                    <td>Paradise Consultant</td>
                                </tr>
                                <tr>
                                    <td>Gupta Exim Factory</td>
                                    <td>Faridabad</td>
                                    <td>2011</td>
                                    <td>Abid Hussain Consultant</td>
                                </tr>
                                <tr>
                                    <td>Escorts Fortis Hospital</td>
                                    <td>New Delhi</td>
                                    <td>2010</td>
                                    <td>Spectral</td>
                                </tr>
                                <tr>
                                    <td>Haldiram Moolchand Hospital</td>
                                    <td>Bikaner (Raj)</td>
                                    <td>2009</td>
                                    <td>Creative Designer</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Factory</td>
                                    <td>Faridabad</td>
                                    <td>2009</td>
                                    <td>Abid Hussain Consultant</td>
                                </tr>
                                <tr>
                                    <td>Bharti Eye Foundation – Great Kailash</td>
                                    <td>Delhi</td>
                                    <td>2008</td>
                                    <td>Archmen/Sigma</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered">
                            <caption style={{ textAlign: 'center', fontWeight: 'bold' }}>Hotels and Restaurants</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Completion Date</th>
                                    <th scope="col">Architect Consultant</th>
                                </tr>
                            </thead>
                            <tbody><tr>
                                <td>Inter Globe Hospitality Pvt Ltd IBIS Hotel</td>
                                <td>Delhi</td>
                                <td>2023</td>
                                <td>IGHPT</td>
                            </tr>
                                <tr>
                                    <td>Pirates of Grills</td>
                                    <td>Lucknow</td>
                                    <td>2023</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Aravali Banquets</td>
                                    <td>Delhi</td>
                                    <td>2023</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala @ Lulu Mall Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>2022</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala @ Defence Colony</td>
                                    <td>Delhi</td>
                                    <td>2022</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>KSN Square Bikanervala</td>
                                    <td>Ghaziabad</td>
                                    <td>2022</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>M3M Urbana Bikanervala</td>
                                    <td>Gurgaon</td>
                                    <td>2022</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Conscient One Mall Bikanervala</td>
                                    <td>Gurgaon</td>
                                    <td>2022</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Hotel Centrum Club Phase II</td>
                                    <td>Lucknow</td>
                                    <td>2023</td>
                                    <td>Envirotech</td>
                                </tr>
                                <tr>
                                    <td>Cube Stop</td>
                                    <td>Khalghat</td>
                                    <td>2021</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Cube Stop</td>
                                    <td>Eklara</td>
                                    <td>2021</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Arun Hotel</td>
                                    <td>Lucknow</td>
                                    <td>2020</td>
                                    <td>Parvez Kausar</td>
                                </tr>
                                <tr>
                                    <td>Sona Sweets</td>
                                    <td>Shahdra</td>
                                    <td>2020</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala RR Mall</td>
                                    <td>Rohini</td>
                                    <td>2020</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala</td>
                                    <td>Patna</td>
                                    <td>2020</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Subham Hotel</td>
                                    <td>Etawah</td>
                                    <td>2019</td>
                                    <td>Parvez Kausar</td>
                                </tr>
                                <tr>
                                    <td>Centrum Club</td>
                                    <td>LUCKNOW</td>
                                    <td>2020</td>
                                    <td>Enviro</td>
                                </tr>
                                <tr>
                                    <td>Deep Hotel</td>
                                    <td>Lucknow</td>
                                    <td>2019</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Saket</td>
                                    <td>2017</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Dilshad Garden</td>
                                    <td>2017</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Kirti Nagar</td>
                                    <td>2017</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Hotel Shani Palace</td>
                                    <td>Mahipalpur</td>
                                    <td>2018</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Agra</td>
                                    <td>2017</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Rohini</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Crystal World Food Court</td>
                                    <td>Roorkee</td>
                                    <td>2017</td>
                                    <td>Gagan Gupta</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Karol Bagh</td>
                                    <td>2017</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Aero-City Food Court</td>
                                    <td>Delhi</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Kunjilal Dal Sew Vale</td>
                                    <td>Aligarh</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Kamla Nagar</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Aligarh</td>
                                    <td>2016</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Golden Tulip Resorts</td>
                                    <td>Bheror</td>
                                    <td>2015</td>
                                    <td>Studio Line Craft</td>
                                </tr>
                                <tr>
                                    <td>Aryan Residency</td>
                                    <td>Greater Noida</td>
                                    <td>2014</td>
                                    <td>NS Associates</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Manesar</td>
                                    <td>2014</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Benihana Restaurant</td>
                                    <td>Delhi</td>
                                    <td>2013</td>
                                    <td>Lotus Design</td>
                                </tr>
                                <tr>
                                    <td>Holiday Inn</td>
                                    <td>Jaipur</td>
                                    <td>2013</td>
                                    <td>Spectral</td>
                                </tr>
                                <tr>
                                    <td>Park Plaza</td>
                                    <td>Faridabad</td>
                                    <td>2013</td>
                                    <td>ACCIL</td>
                                </tr>
                                <tr>
                                    <td>Grand Hira Resorts</td>
                                    <td>Neemrana</td>
                                    <td>2013</td>
                                    <td>Studio Line Craft</td>
                                </tr>
                                <tr>
                                    <td>Bikanervala Restaurant</td>
                                    <td>Greater Kailash</td>
                                    <td>2013</td>
                                    <td>RMDK Projects</td>
                                </tr>
                                <tr>
                                    <td>KFC</td>
                                    <td>Kolkata</td>
                                    <td>2012</td>
                                    <td>Devyani International</td>
                                </tr>
                                <tr>
                                    <td>IGI Plaza Lounge</td>
                                    <td>New Delhi</td>
                                    <td>2012</td>
                                    <td>Genesis</td>
                                </tr>
                                <tr>
                                    <td>KFC</td>
                                    <td>Asansol</td>
                                    <td>2012</td>
                                    <td>Devyani International</td>
                                </tr>
                                <tr>
                                    <td>KFC</td>
                                    <td>Ghaziabad</td>
                                    <td>2012</td>
                                    <td>Devyani International</td>
                                </tr>
                                <tr>
                                    <td>Biknerwala Food Court</td>
                                    <td>Delhi</td>
                                    <td>2011</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Plaza Lounge</td>
                                    <td>Delhi</td>
                                    <td>2009</td>
                                    <td>GMR</td>
                                </tr>
                                <tr>
                                    <td>Bikanevala Food Court</td>
                                    <td>Delhi/NCR/Allahabad</td>
                                    <td>2009</td>
                                    <td>Kalra / Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Centaur Hotel</td>
                                    <td>Delhi</td>
                                    <td>2008</td>
                                    <td>ADPL/Abid Hussain</td>
                                </tr>
                                <tr>
                                    <td>Barista Coffee Lounge</td>
                                    <td>Delhi/Noida</td>
                                    <td>2008</td>
                                    <td>Genesis</td>
                                </tr>
                                <tr>
                                    <td>South Delhi Hotels Pvt Ltd</td>
                                    <td>Gurgaon</td>
                                    <td>2007</td>
                                    <td>Archmen</td>
                                </tr>
                            </tbody></table>
                        <table className="table table-bordered">
                            <caption style={{ textAlign: 'center', fontWeight: 'bold' }}>Commercial and Institutional</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Completion Date</th>
                                    <th scope="col">Architect Consultant</th>
                                </tr>
                            </thead>
                            <tbody><tr>
                                <td>New Office Abid Husain Consultant Delhi</td>
                                <td>Delhi</td>
                                <td>2023</td>
                                <td>Abid Husain Consultant</td>
                            </tr>
                                <tr>
                                    <td>Anjuman tarraqi Urdu(Hind)</td>
                                    <td>Delhi</td>
                                    <td>2023</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Shopers Stop Store</td>
                                    <td>Prayagraj</td>
                                    <td>2023</td>
                                    <td>Vision EMC Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td>Gurudwara Ajit Darbar</td>
                                    <td>Delhi</td>
                                    <td>2023</td>
                                    <td>Abid Husain Consultant</td>
                                </tr>
                                <tr>
                                    <td>UNO Minda</td>
                                    <td>Gurgaon</td>
                                    <td>2022</td>
                                    <td>Abid Husain Consultant</td>
                                </tr>
                                <tr>
                                    <td>CMS Ansal Sushant Lok Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>2022</td>
                                    <td>Mega Infra</td>
                                </tr>
                                <tr>
                                    <td>CMS Rajajipuram Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>Running</td>
                                    <td>Mega Infra</td>
                                </tr>
                                <tr>
                                    <td>CMS Jopling Road Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>Running</td>
                                    <td>Mega Infra</td>
                                </tr>
                                <tr>
                                    <td>CMS Gonti Nagar Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>2022</td>
                                    <td>Mega Infra</td>
                                </tr>
                                <tr>
                                    <td>Spectrum Metro Mall-Block-B</td>
                                    <td>Noida</td>
                                    <td>2022</td>
                                    <td>Proion</td>
                                </tr>
                                <tr>
                                    <td>Jhansi Smart City Infra</td>
                                    <td>Jhansi</td>
                                    <td>2020</td>
                                    <td>Netcon Technologies</td>
                                </tr>
                                <tr>
                                    <td>Kuldeep Motors</td>
                                    <td>Lucknow</td>
                                    <td>2020</td>
                                    <td>Self</td>
                                </tr>
                                <tr>
                                    <td>Deep PVR</td>
                                    <td>Kanpur</td>
                                    <td>2020</td>
                                    <td>R S Consultant</td>
                                </tr>
                                <tr>
                                    <td>True Value</td>
                                    <td>Lucknow</td>
                                    <td>2019</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>AKM Motors</td>
                                    <td>Lucknow</td>
                                    <td>2019</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>Aspam Scottish School</td>
                                    <td>Noida</td>
                                    <td>2022</td>
                                    <td>AD Consultant</td>
                                </tr>
                                <tr>
                                    <td>Gulf Adiba</td>
                                    <td>Gurgaon</td>
                                    <td>2019</td>
                                    <td>Serene Design</td>
                                </tr>
                                <tr>
                                    <td>Metro City Centre</td>
                                    <td>Lucknow</td>
                                    <td>2019</td>
                                    <td>AES</td>
                                </tr>
                                <tr>
                                    <td>CBSE Raibareli</td>
                                    <td>Raibareli</td>
                                    <td>2017</td>
                                    <td>NBCC</td>
                                </tr>
                                <tr>
                                    <td>Allahabad Bank CP -Phase -II</td>
                                    <td>Delhi</td>
                                    <td>2017</td>
                                    <td>NBCC</td>
                                </tr>
                                <tr>
                                    <td>Jamia Co-operative Bank _HO</td>
                                    <td>Delhi</td>
                                    <td>2016</td>
                                    <td>3X</td>
                                </tr>
                                <tr>
                                    <td>Director General &amp; Supplies &amp; Disposables (HO )</td>
                                    <td>Delhi</td>
                                    <td>2017</td>
                                    <td>NBCC</td>
                                </tr>
                                <tr>
                                    <td>Apex Florus</td>
                                    <td>Ghaziabad</td>
                                    <td>2016</td>
                                    <td>Sanjay Constructions</td>
                                </tr>
                                <tr>
                                    <td>Eden Heights</td>
                                    <td>Gurgaon</td>
                                    <td>2016</td>
                                    <td>AHCON</td>
                                </tr>
                                <tr>
                                    <td>Director General Anti Dumping -Phase-II</td>
                                    <td>Delhi</td>
                                    <td>2016</td>
                                    <td>Quazar Infra Pvt Ltd</td>
                                </tr>
                                <tr>
                                    <td>Allahabad Bank CP</td>
                                    <td>Delhi</td>
                                    <td>2015</td>
                                    <td>NBCC</td>
                                </tr>
                                <tr>
                                    <td>BITS Pilani</td>
                                    <td>Pilani</td>
                                    <td>2015</td>
                                    <td>AECOM</td>
                                </tr>
                                <tr>
                                    <td>Green Ply</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>Global Engg</td>
                                </tr>
                                <tr>
                                    <td>Director General Anti Dumping</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>NBCC</td>
                                </tr>
                                <tr>
                                    <td>Liliput Kids wear ltd</td>
                                    <td>Mumbai</td>
                                    <td>2011</td>
                                    <td>In House</td>
                                </tr>
                                <tr>
                                    <td>Liliput Kids wear ltd</td>
                                    <td>Bangalore</td>
                                    <td>2011</td>
                                    <td>In House</td>
                                </tr>
                                <tr>
                                    <td>DDFS at IGI Airport</td>
                                    <td>Delhi</td>
                                    <td>2010</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Pearl Mineral</td>
                                    <td>Delhi</td>
                                    <td>2009</td>
                                    <td>Creative</td>
                                </tr>
                                <tr>
                                    <td>Kaver Prints Process</td>
                                    <td>Delhi</td>
                                    <td>2009</td>
                                    <td>Design Art</td>
                                </tr>
                                <tr>
                                    <td>Coloston Stores</td>
                                    <td>Faridabad</td>
                                    <td>2009</td>
                                    <td>Creative</td>
                                </tr>
                                <tr>
                                    <td>ED hardy Stores</td>
                                    <td>Delhi</td>
                                    <td>2008</td>
                                    <td>Design Art</td>
                                </tr>
                                <tr>
                                    <td>Louis Dreyfus</td>
                                    <td>Gurgaon</td>
                                    <td>2008</td>
                                    <td>Genesis</td>
                                </tr>
                                <tr>
                                    <td>Art Gallery</td>
                                    <td>Delhi</td>
                                    <td>2008</td>
                                    <td>KAPL</td>
                                </tr>
                                <tr>
                                    <td>REI Agro</td>
                                    <td>Delhi</td>
                                    <td>2007</td>
                                    <td>Genesis</td>
                                </tr>
                                <tr>
                                    <td>Tally Softwares</td>
                                    <td>Delhi</td>
                                    <td>2007</td>
                                    <td>Archmen</td>
                                </tr>
                                <tr>
                                    <td>UPS Jet Pvt Ltd</td>
                                    <td>Delhi</td>
                                    <td>2007</td>
                                    <td>Design Art</td>
                                </tr>
                                <tr>
                                    <td>Embassy of Italy</td>
                                    <td>Delhi</td>
                                    <td>2007</td>
                                    <td>KAPL</td>
                                </tr>
                            </tbody></table>
                        <table className="table table-bordered">
                            <caption style={{ textAlign: 'center', fontWeight: 'bold' }}>Residential</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Completion Date</th>
                                    <th scope="col">Architect Consultant</th>
                                </tr>
                            </thead>
                            <tbody><tr>
                                <td>DLF Camelias 616A</td>
                                <td>Gurgaon</td>
                                <td>2022</td>
                                <td>Self</td>
                            </tr>
                                <tr>
                                    <td>Ar. Gyan P Mathur Residence</td>
                                    <td>Delhi</td>
                                    <td>2018</td>
                                    <td>In House</td>
                                </tr>
                                <tr>
                                    <td>Agarwal Residence</td>
                                    <td>Delhi</td>
                                    <td>2018</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>Metro City Centre -Pent House</td>
                                    <td>Lucknow</td>
                                    <td>2018</td>
                                    <td>AES</td>
                                </tr>
                                <tr>
                                    <td>Ravi Rishi's</td>
                                    <td>Delhi</td>
                                    <td>2016</td>
                                    <td>Kacker &amp; Asso</td>
                                </tr>
                                <tr>
                                    <td>Gulmohar House</td>
                                    <td>Delhi</td>
                                    <td>2014</td>
                                    <td>Vipin Chawla &amp; Asso</td>
                                </tr>
                                <tr>
                                    <td>PH Parikh</td>
                                    <td>Delhi</td>
                                    <td>2009</td>
                                    <td>Abid Hussain Consulatant</td>
                                </tr>
                                <tr>
                                    <td>Sajal Gupta Residence</td>
                                    <td>Delhi</td>
                                    <td>2008</td>
                                    <td>In-House</td>
                                </tr>
                                <tr>
                                    <td>Max's Hospital Chairman Residence</td>
                                    <td>Delhi</td>
                                    <td>2006</td>
                                    <td>KAPL</td>
                                </tr>
                                <tr>
                                    <td>UP Chief Minister Guest House</td>
                                    <td>Delhi</td>
                                    <td>2006</td>
                                    <td>Kothari Associates</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default ProjectTable