import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Aboutus from '../../components/about-us/Aboutus'
import Journey from '../../components/journey/Journey'
import { MENU_TABS } from '../../domain/constant'



function About() {
    return (
        <div>
            <Header activeTab={MENU_TABS.ABOUT} />
            <Breadcrumb title={`About Us`} />
            <Aboutus />
            <div className="row middle-content ptb-45">
                <div className="col-md-4">
                    <div className="service-footer-card">
                        <div className="service-footer-card-heading">
                            <img src="/assets/images/icon/mission.png" alt="" />
                            <p>Mission</p>
                        </div>
                        <div className="service-footer-card-content">
                            <p> To enhance the quality of indoor environments through innovative Comfort Air conditioning solutions. We are dedicated to creating spaces of luxury and comfort where people can thrive, work, and relax, while prioritizing energy efficiency and environmental sustainability.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-footer-card">
                        <div className="service-footer-card-heading">
                            <img src="/assets/images/icon/achievement.png" alt="" />
                            <p>Vision</p>
                        </div>
                        <div className="service-footer-card-content">
                            <p>To be the foremost provider of Comfort Air conditioning services, setting new industry standards for creating luxurious and inviting indoor environments. We envision a future where every space we touch becomes a haven of comfort, technology, and a profound understanding of human well-being.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-footer-card">
                        <div className="service-footer-card-heading">
                            <img src="/assets/images/icon/rating.png" alt="" />
                            <p>Experience</p>
                        </div>
                        <div className="service-footer-card-content">
                            <p> Our team of dedicated experts combines cutting-edge technology with artistic finesse to create environments that delight the senses and elevate lifestyles. Our commitment to you extends beyond temperature control; it's about crafting moments of indulgence and tranquility.</p>
                        </div>

                        {
                            // <p> We listen to your needs, collaborate on your vision, and implement solutions that surpass your expectations. From luxurious hotels to cozy homes, from bustling offices to serene spas, we specialize in tailoring Comfort Air conditioning to your unique requirements. Your comfort is our canvas, and your satisfaction is our masterpiece</p>
                        }
                    </div>
                </div>

            </div>
            <section className="our-team">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title text-center ptb-10">
                                <h1>OUR EXPERTS</h1>
                                <p>
                                    Our team of seasoned professionals comprises experts in the field who bring a wealth of knowledge and experience. With a commitment to excellence, they blend technical mastery with creative finesse, ensuring that every project is executed with precision and innovation. Meet the dedicated individuals behind our success, driving the commitment to deliver exceptional results for our valued clients.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row ptb-30">
                        <div className="col-md-6">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>AFZAL ZAHEER KHAN</h3>
                                    <p>Managing Director</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>Meet Mr. Afzal Zaheer Khan, our esteemed Managing Director. With a professional background in Mechanical Engineering and an impressive 17-year track record in the HVAC industry, he brings a wealth of experience to our team. His visionary approach is dedicated to delivering integrated solutions in building engineering services.!</p>

                                    <p>At System AirConditioning Pvt. Ltd. we're proud to have Mr. Afzal Zaheer Khan leading the way, ensuring that our clients receive the highest quality services and innovative solutions. With his leadership, we are committed to excellence in every project we undertake. Mr. Khan's extensive experience and forward-thinking approach drive our commitment to innovation and client satisfaction.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>FAIZAN AKHTAR</h3>
                                    <p>Director technical & execution</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>Meet Mr. Faizan Akhtar, our accomplished Director of Technical & Execution. With a distinguished career spanning over two decades, Mr. Akhtar is a seasoned Mechanical Engineer renowned for his expertise in designing, planning, and execution within the industry. As a dedicated professional, he brings a wealth of experience and a keen eye for detail to every project. Mr. Akhtar's unwavering commitment to excellence is reflected in our company's approach to delivering exceptional services.</p>

                                    <p>At System AirConditioning Pvt. Ltd., we are proud to have Mr. Faizan Akhtar at the helm of our technical and execution teams. His leadership ensures that our projects are not only meticulously planned but also executed to perfection.</p>


                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>MR MASOOD HUSAIN</h3>
                                    <p>Director Procurement Service & Complaints</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>Introducing Mr. Masood Husain, our esteemed Director of Procurement, Administration, and Service & Complaints. With over 12 years of invaluable experience, Mr. Husain brings a unique perspective to our team, boasting a non-engineering background that complements our diverse skill set.</p>

                                    <p>In his role, Mr. Husain oversees procurement, administration, and ensures top-notch service delivery while also addressing any concerns or complaints for our Pan India projects. His dedication to efficiency, quality, and customer satisfaction is unmatched.</p>

                                    <p>At System AirConditioning Pvt. Ltd., we take pride in having Mr. Masood Husain spearheading these critical functions. </p>

                                    {
                                        // <p>
                                        //     His leadership ensures that our operations run seamlessly and our clients receive exceptional support throughout their journey with us.
                                        // </p>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>AKRAM ZAHEER KHAN</h3>
                                    <p>Head Business Development</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>Meet Mr. Akram Zaheer Khan, our dynamic Head of Business Development, overseeing our operations across Pan India. With an impressive career spanning over 25 years, Mr. Khan is a seasoned sales professional who brings a wealth of experience and a proven track record to our team.</p>

                                    <p>In his role, Mr. Khan is dedicated to driving our business growth through strategic partnerships, client relations, and innovative sales initiatives. His deep understanding of the industry and market trends is a driving force behind our success.</p>

                                    <p>At System AirConditioning Pvt. Ltd., we are honoured to have Mr. Akram Zaheer Khan leading our business development efforts. Under his guidance, we continue to expand our reach and deliver exceptional solutions to our clients.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mx-auto">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>SUBODH SRIVASTAVA</h3>
                                    <p>Head Business Development (North-Eastern India)</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>Mr. Subodh Srivastava is a seasoned professional with over 12 years of sales experience. In his current role he oversees the growth and expansion of our operations in the regions of Uttar Pradesh (U.P) and Northeastern India.</p>

                                    <p>In addition to his business development responsibilities, Mr. Srivastava also plays a pivotal role in managing administrative tasks and addressing service and complaint-related issues within his region. His multifaceted approach ensures that our clients receive not only top-notch products and services but also exceptional customer care and support.</p>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mx-auto">
                            <div className="our-team-member-content">
                                <div className="our-team-member-name">
                                    <h3>Know More</h3>
                                    <p>About Our Experts</p>
                                </div>

                                <div className="our-team-member-info">
                                    <p>At System AirConditioning Pvt. Ltd., our leadership team embodies a wealth of experience, expertise, and a commitment to excellence in the HVAC industry. Under the visionary guidance of our Managing Director, Mr. Afzal Zaheer Khan, and the dedicated efforts of our Directors and Heads, we consistently strive for innovation, precision, and client satisfaction.</p>

                                    <p>As we continue to redefine industry standards and deliver exceptional solutions, we invite you to explore the possibilities with us. Whether you are considering an HVAC project, seeking technical expertise, or exploring career opportunities, System Air Conditioning Pvt. Ltd. is your trusted partner.</p>

                                    <p>Join us in creating comfortable, efficient, and sustainable environments. Contact us today to experience the excellence that defines our work and discover how we can contribute to the success of your projects. Let's build a future of innovation and comfort together.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default About