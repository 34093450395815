import React, { useState } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import { MENU_TABS } from '../../domain/constant'
import { Form, Formik } from 'formik'
import { SelectBox, TextArea, TextInput } from '../../components/Form/Form'
// import { SelectBox, TextArea, TextInput } from '../Form/Form'
// import { useCreateAppointment } from "../../shared/hooks/UseAppointment";
// import { useSelectAllService } from "../../shared/hooks/UseService"
import * as yup from "yup"


function ContactUs() {
    const [showGreeting, setSetshowGreeting] = useState(false)

    const addContact = ({ values }) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://luhaifdigih.com/mailer/mail.php?name=${values.name}&phone=${values.phone}&email=${values.email}&message=${values.message}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
    // console.log("Check if open", openModel);
    // const handleModalClose = () => {

    //     setOpenModel(false);
    //     console.log("Closing Modal", openModel);
    // }

    return (
        <div>
            <Header activeTab={MENU_TABS.CONTACT_US} />
            <Breadcrumb title={`Contact Us`} />
            <section className="contact-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="contact-info-card">
                                <div className="contact-info-content">
                                    <div className="icon-holder contact-icon">
                                        <span className="flaticon-pin" />
                                    </div>
                                    <div className="contact-info-text">
                                        <h5>
                                            <span>(Head-Office) Address:</span>  G 44, 3rd Floor, Kalindi Kunj Road, Shaheen Bagh, Okhla, New Delhi - 1100025.
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className="icon-holder contact-icon">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <div className="contact-info-text mt-4">
                                        <h5>
                                            <strong>Delhi Office: <a href="tel:01141012538">(011)-41012538</a></strong>
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className="icon-holder contact-icon">
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <div className="contact-info-text-mail mt-5">
                                        <h5>
                                            <strong><a href="mail:info@systemairconditioning.com">info@systemairconditioning.com</a></strong>
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className="icon-holder contact-icon">
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <div className="contact-info-text-mail mt-5">
                                        <h5>
                                            <strong><a href="mail:systemairconditioninglko@gmail.com">systemairconditioninglko@gmail.com</a></strong>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info-card mt-12">
                                <div className="contact-info-content">
                                    <div className="icon-holder contact-icon">
                                        <span className="flaticon-pin" />
                                    </div>
                                    <div className="contact-info-text">
                                        <h5>
                                            <span>(Regional-Office) Address:</span>  V.D.A Complex, Shop No 17, Purani Chungi, Shivpur, Opposite Swastik Gardenia, Varanasi, Uttar Pradesh - 221003
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className="icon-holder contact-icon">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <div className="contact-info-text mt-4">
                                        <h5>
                                            <strong>Central Number: <a href="tel:+918130294043">+91-81302-94043</a></strong>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info-card mt-12">
                                <div className="contact-info-content">
                                    <div className="icon-holder contact-icon">
                                        <span className="flaticon-pin" />
                                    </div>
                                    <div className="contact-info-text">
                                        <h5>
                                            <span>(Branch-Office) Address:</span>  Plot No. 57, Vikas Bagh, Sector 7, Vikas Nagar, Lucknow, Uttar Pradesh - 226022.
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div className="icon-holder contact-icon">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                    <div className="contact-info-text mt-4">
                                        <h5>
                                            <strong>Central Number: <a href="tel:+918130294043">+91-81302-94043</a></strong>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="formik-form">
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        phone: '',
                                        message: '',
                                    }}
                                    validationSchema={yup.object({
                                        name: yup.string().required('Required'),
                                        email: yup.string().required('Required'),
                                        phone: yup.string().required('Required'),
                                    })}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        // setSubmitting(true);
                                        // addContact({ values });
                                        setSetshowGreeting(!showGreeting)
                                        resetForm();
                                        setSubmitting(false);
                                    }}
                                >
                                    {(formik) => {
                                        console.log(formik);
                                        return (
                                            <Form className='request_form '>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="d-flex-justify-space-btn">

                                                            <div class="modal-appointment-form-heading">
                                                                <h5 className='text-center'>Make an Appointment</h5>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12 mt-4">
                                                        <TextInput
                                                            label="Name"
                                                            name="name"
                                                            type="text"
                                                            placeholder="Enter name"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <TextInput
                                                            label="Email"
                                                            name="email"
                                                            type="text"
                                                            placeholder="Enter email"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <TextInput
                                                            label="Phone"
                                                            name="phone"
                                                            type="text"
                                                            placeholder="Enter phone"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <textarea
                                                            name="message"
                                                            id="message"
                                                            cols="42"
                                                            rows="10"
                                                            type="text"
                                                            placeholder="Enter message" />
                                                    </div>
                                                </div>
                                                <div className="contact-form-btn">
                                                    <div className="form-submit-btn">
                                                        <button
                                                            type='submit'>Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section className="map">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="embeded-map">
                                <div className="">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.5123837488313!2d77.29719827601438!3d28.554372787624715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce411b42ab049%3A0x166819289f619494!2sKalindi%20Kunj%20Rd%2C%20Okhla%2C%20New%20Delhi%2C%20Delhi%20110025!5e0!3m2!1sen!2sin!4v1700930889292!5m2!1sen!2sin" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ContactUs