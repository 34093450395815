import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import { ProjectData } from '../../models/project'
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { MENU_TABS } from '../../domain/constant'


function Project() {

    const [project, setProject] = useState(ProjectData)
    const [activemenu, setActivemenu] = useState('ALL')


    useEffect(() => {
        if (activemenu != 'ALL') {
            const filterData = project.filter((item) => item.category == activemenu)
            setProject(filterData)
        } else {
            setProject(project)
        }
    }, [activemenu])

    let subtitle;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const [activeImage, setActiveImage] = useState(null)
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(image) {
        setActiveImage(image)
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <Header activeTab={MENU_TABS.PROJECT} />
            <Breadcrumb title={`Projects`} />
            <section className="certificate ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="project-categories">
                            <div
                                className={activemenu == 'ALL' ? 'project-category-tab project-active' : 'project-category-tab'}
                                onClick={() => setActivemenu('ALL')}>
                                All Projects
                            </div>
                            <div
                                className={activemenu == 'HOSPITALS' ? 'project-category-tab project-active' : 'project-category-tab'}
                                onClick={() => setActivemenu('HOSPITALS')}
                            >
                                Hospitals
                            </div>
                            <div className={activemenu == 'HOTELS' ? 'project-category-tab project-active' : 'project-category-tab'}
                                onClick={() => setActivemenu('HOTELS')}>
                                Hotels and Restaurants
                            </div>
                            <div className={activemenu == 'INSTITUTIONS' ? 'project-category-tab project-active' : 'project-category-tab'}
                                onClick={() => setActivemenu('INSTITUTIONS')}>
                                Institutional
                            </div>
                            <div className={activemenu == 'RESIDENTIAL' ? 'project-category-tab project-active' : 'project-category-tab'}
                                onClick={() => setActivemenu('RESIDENTIAL')}>
                                Residential
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            project && project.map((item) => {
                                return (
                                    <div className="col-md-4 col-sm-4 col-xs-12 filter-item maintenance airconditioning mb-24">
                                        <div className="certificate-card">
                                            <div className="certificate-card-img brand-images-project">
                                                <img onClick={() => openModal(item.image)} src={item.image} alt="" />
                                            </div>
                                            <div className="certificate-card-title">
                                                <h4>{item.title}</h4>
                                                <p>{item.location}</p>
                                                <h6>{item.description}</h6>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                    {activeImage && (
                        <Lightbox image={activeImage} onClose={() => setActiveImage(null)} >
                        </Lightbox>
                    )}
                    <div className="row">
                        <div className="see-more-btn">
                            <button>See More</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Project