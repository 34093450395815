export const ProjectDetails = [
    {
        title: "Inter Globe Hospitality Pvt Ltd IBIS Hotel ",
        location: "Delhi",
        desc: "IGHPT"
    },
    {
        title: "Pirates of Grills",
        location: "Lucknow",
        desc: "SELF"
    },
    {
        title: "Aravali Banquets ",
        location: "Delhi",
        desc: "SELF"
    },
    {
        title: "Bikanervala at Lulu  Mall ",
        location: "Delhi",
        desc: "IGHPT"
    },
    {
        title: "Inter Globe Hospitality Pvt Ltd IBIS Hotel ",
        location: "Lucknow",
        desc: "ADPL/ABID HUSSAIN"
    },
    {
        title: "KSN Square Bikanerwala",
        location: "Ghaziabad",
        desc: "ADPL/ABID HUSSAINPT"
    },
    {
        title: "M3M Urbana Bikanervala",
        location: "Gurgaon",
        desc: "ADPL/ABID HUSSAINPT"
    },
    {
        title: "Conscient One Mall Bikanervala",
        location: "Gurgaon",
        desc: "ADPL/ABID HUSSAINPT"
    },
    {
        title: "Centaur Hotel",
        location: "Delhi",
        desc: "ADPL/ABID HUSSAINPT"
    },
    {
        title: "Hotel Centrum Club Phase II",
        location: "Lucknow",
        desc: "ENVIROTECH"
    },
    {
        title: "Cube Stop",
        location: "Kalghat",
        desc: "SELF"
    },
    {
        title: "Cube Stop",
        location: "Eklara",
        desc: "SELF"
    },
    {
        title: "Arun Hotel",
        location: "Lucknow",
        desc: "Parvez Kausar"
    },
    {
        title: "Sona Sweet",
        location: "Shahdra",
        desc: "SELF"
    },
    {
        title: "Bikanervala RR Mall",
        location: "Rohini",
        desc: "SELF"
    },
]