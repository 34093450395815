import React, { useState } from 'react'
import Slider from "react-slick";
import { ProjectData } from '../../models/project'
import { Link } from 'react-router-dom';



function Product() {

    var settings = {
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        arrows: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [project, setProject] = useState(ProjectData)

    return (
        <div>
            <section className="projects-sec ptb-60">
                <div className="container">
                    <div className="title text-center">
                        <h1>Our Projects</h1>
                        <p>
                            Explore a glimpse of our accomplished projects.
                        </p>
                    </div>
                    <div className="row">
                        <Slider {...settings}>
                            {
                                project && project.map((item) => {
                                    return (
                                        <div className="col-md-4 col-sm-4 col-xs-12 filter-item maintenance airconditioning ptb-30 ">
                                            <div className="certificate-card">
                                                <div className="certificate-card-img brand-images-project">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="certificate-card-title">
                                                    <h4>{item.title}</h4>
                                                    <p>{item.location}</p>
                                                    <h6>{item.description}</h6>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }

                        </Slider>
                        <div className="row">
                            <div className="see-more-btn">
                                <Link to="/projects">
                                    <button>See More</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Product