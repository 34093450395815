import React, { useState, useEffect } from 'react'
import { ServiceData } from '../../models/service'
import { Link } from 'react-router-dom';
import { MENU_TABS } from '../../domain/constant';
import BookAppointmentButton from '../common/BookAppointmentButton';


function Header({ activeTab = MENU_TABS.HOME }) {

    const [service, setservice] = useState(ServiceData)
    const [openModel, setOpenModel] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.href]);

    const [mobileNav, setMobileNav] = useState(false)
    const [mobilemenu, setmobilemenu] = useState(false)
    return (
        <div>
            <section className="top-bar-area only-desktop">
                <div className="container">
                    <div className="top-bar">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="header-top-content blink_me  d-flex">
                                    <div className="header-top-content-icon">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div className="header-top-content-text">
                                        <p>Authorized Channel Partner of Daikin</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="header-top-content blink_me  d-flex">
                                    <div className="header-top-content-icon">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                    <div className="header-top-content-text">
                                        <p>ISO certified 9001:2015</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="header-top-content blink_me  d-flex">
                                    <div className="header-top-content-icon">
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                    </div>
                                    <div className="header-top-content-text">
                                        <a href="mail:Info@systemairconditioning.com">
                                            <p>Info@systemairconditioning.com</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <header className="header-area only-desktop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="logo">
                                <Link to="/">
                                    <img src="/assets/images/System.png" alt="Awesome Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="header-contact-info pull-left">
                                        <ul>
                                            <li>
                                                <div className="iocn-holder">
                                                    <span className="flaticon-technology" />
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Call us now</h5>
                                                    <h6><a href="tel:813-029-4043">813-029-4043</a></h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="iocn-holder">
                                                    <span className="flaticon-pin" />
                                                </div>
                                                <div className="text-holder">
                                                    <h5>G 44, 3rd Floor, Kalindi Kunj Road</h5>
                                                    <h6>Shaheen Bagh, Okhla, New Delhi - 1100025.</h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="iocn-holder">
                                                    <span className="flaticon-agenda" />
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Mon - Satday</h5>
                                                    <h6>09:00am to 18:00pm</h6>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        // <div className="search-button pull-right">
                                        //   <div className="toggle-search">
                                        //     <button>
                                        //       <i className="fa fa-search" aria-hidden="true" />
                                        //     </button>
                                        //   </div>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {
                // <section className="header-search only-desktop">
                //     <div className="container">
                //         <div className="row">
                //             <div className="col-md-12 col-sm-12 col-xs-12">
                //                 <div className="search-form pull-right">
                //                     <form action="#">
                //                         <div className="search">
                //                             <input type="search" name="search" defaultValue placeholder="Search" />
                //                             <button type="submit">
                //                                 <i className="fa fa-search" aria-hidden="true" />
                //                             </button>
                //                         </div>
                //                     </form>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </section>
            }

            <section className="mainmenu-area stricky  only-desktop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            {/*Start mainmenu*/}
                            <nav className="main-menu">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li className={activeTab === MENU_TABS.HOME && 'current'}>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className={activeTab === MENU_TABS.ABOUT ? 'dropdown current' : 'dropdown'}>
                                            <Link to="/about-us">About Us</Link>

                                            {
                                                // <ul>
                                                //     <li>
                                                //         <a href="#">About System Air Conditioning </a>
                                                //     </li>
                                                //     <li><a href="index.html">Meet Our Team</a></li>
                                                //     <li><a href="index.html">Our Projects</a></li>
                                                //     <li><a href="index.html">Projects Single</a></li>
                                                //     <li><a href="index.html">FAQ’s</a></li>
                                                // </ul>
                                            }
                                        </li>
                                        <li className={activeTab === MENU_TABS.SERVICE ? 'dropdown current' : 'dropdown'}>

                                            <a>Services</a>
                                            <ul>
                                                <li>
                                                    <Link to="/services">All Services</Link>
                                                </li>
                                                <li>
                                                    <Link to="/comfort-air-conditioning">Comfort Air Conditioning</Link>
                                                </li>
                                                <li>
                                                    <Link to="/precision-air-conditioning">Precision Air Conditioning</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className={activeTab === MENU_TABS.PROJECT ? 'dropdown current' : 'dropdown'}>
                                            <Link>Projects</Link>
                                            <ul>
                                                <li>
                                                    <Link to="/projects">Projects</Link>
                                                </li>
                                                {
                                                    <li>
                                                        <Link to="/project-table">Project Table</Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link to="/project-details">Project Details</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className={activeTab === MENU_TABS.CERTIFICATE && 'current'}>
                                            <Link to="/certificates">Certificates</Link>
                                        </li>
                                        <li className={activeTab === MENU_TABS.CONTACT_US && 'current'}>
                                            <Link to="/contact-us">Contacts</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                            {/*End mainmenu*/}
                            <div className="appoinment-button" onClick={() => setOpenModel(true)}>
                                <a href="#">Make an Appoinment</a>
                            </div>
                            <BookAppointmentButton openModel={openModel} setOpenModel={setOpenModel} />
                        </div>
                    </div>
                </div>
            </section>

            {
                <div className="mobile-header only-mobile ">
                    <div className="container">
                        <div className="mobile-nav">
                            <div className="mobile-navigation-tabs d-flex">
                                <div className="logo">
                                    <Link className="mobile-logo" to="/">
                                        <img src="/assets/images/System.png" alt="Awesome Logo" />
                                    </Link>
                                </div>

                                <div className="mobile-nav-btn">
                                    <div onClick={() => setMobileNav(!mobileNav)}>
                                        <div
                                            className={
                                                mobilemenu
                                                    ? 'responsive-toggle fa fa-close'
                                                    : 'responsive-toggle fa fa-bars'
                                            }
                                        />
                                    </div>
                                    {mobileNav ? (
                                        <section>
                                            <div className="mobile-navbar-menu">
                                                <div className="logo">
                                                    <Link className="mobile-logo" to="/">
                                                        <img src="/assets/images/System.png" alt="Awesome Logo" />
                                                    </Link>
                                                </div>
                                                <i className="fa fa-close clr-txt mobile-nav-close-icon" onClick={() => setMobileNav(false)} />

                                                <hr />
                                                <div className="mobile-menu-links">
                                                    <ul>
                                                        <li>
                                                            <Link to="/">
                                                                Home
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about-us">
                                                                <p>About Us</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/services">
                                                                <p>Services</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/projects">
                                                                <p>Projects</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact-us">
                                                                <p>Contact Us</p>
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                                <hr />
                                                <section className="top-bar-area">
                                                    <div className="container">
                                                        <div className="top-bar">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="header-top-content blink_me  d-flex">
                                                                        <div className="header-top-content-icon">
                                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                                        </div>
                                                                        <div className="header-top-content-text">
                                                                            <p>Authorized Channel Partner of Daikin</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="header-top-content blink_me  d-flex">
                                                                        <div className="header-top-content-icon">
                                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                                        </div>
                                                                        <div className="header-top-content-text">
                                                                            <p>ISO certified 9001:2015</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="header-top-content blink_me  d-flex">
                                                                        <div className="header-top-content-icon">
                                                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                                                        </div>
                                                                        <div className="header-top-content-text">
                                                                            <a href="mail:Info@systemairconditioning.com">
                                                                                <p>Info@systemairconditioning.com</p>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </section>
                                            </div>
                                        </section>
                                    ) : (null)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </div>
    )
}

export default Header