export const ProjectData = [
    {
        image: '/assets/images/projects/paras.png',
        title: 'Paras Hospital',
        location: " Ranchi",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/Regency-Hospital-LKO.jpg',
        title: 'Regency Hospital',
        location: " Kanpur",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/Bharti-Eye.jpeg',
        title: 'Bharti Eye Foundation',
        location: "Delhi",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/amrita.jpeg',
        title: 'Mata Amrita Devi Foundation',
        location: "Faridabad",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/mooolchand.jpeg',
        title: 'Haldiram Moolchand Hospital',
        location: "Bikaner",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/yashoda.jpeg',
        title: 'Yashoda Hospital',
        location: "Kaushambi",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/dr_original.png',
        title: 'Shri Ganga Ram Hospital',
        location: "Delhi",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/amrita.jpeg',
        title: 'The Partition Museum',
        // subtitle: 'Design Work',
        category: 'HOSPITALS',

    },
    {
        image: '/assets/images/projects/KFC.jpg',
        title: 'KFC',
        location: "Kolkata",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/cube-stop-indore.jpg',
        title: 'Cube Stop',
        location: "Indore",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/Centrum.jpg',
        title: 'Centaur Hotel',
        location: "Delhi",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/lulu.jpeg',
        title: 'LuLu Mall',
        location: "Lucknow",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/Holiday-inn-jaipur.jpeg',
        title: 'Holiday INN',
        location: "Jaipur",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/shoper.jpeg',
        title: 'Shoppers Stop Store',
        location: "Prayagraj",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/spec.jpeg',
        title: 'Spectrum Metro Mall Block-C',
        location: "Noida",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'HOTELS',

    },
    {
        image: '/assets/images/projects/aspam.jpeg',
        title: 'Spam Scottish School',
        location: "Noida",
        description: "Architect Consultant: Acrobat Engg. Pvt Ltd",
        category: 'INSTITUTIONS',

    },
    {
        image: '/assets/images/projects/bits-pilani-sixteen_nine.png',
        title: 'BITS Pilani',
        location: "Pilani",
        description: "Architect Consultant: FCOM",
        category: 'INSTITUTIONS',

    },
    {
        image: '/assets/images/projects/BITS-Pilani.jpeg',
        title: 'BITS Pilani',
        location: "Pilani",
        description: "Architect Consultant: AECOM",
        category: 'INSTITUTIONS',

    },
    {
        image: '/assets/images/projects/gurduwara.jpeg',
        title: 'Gurudwara Ajit Darbar',
        location: "Delhi",
        description: "Architect Consultant: Abid Hussain Consultant",
        category: 'INSTITUTIONS',

    },
    {
        image: '/assets/images/projects/Gupta-Exim-Faridabad.jpeg',
        title: 'Gupta Exim Faridabad',
        location: "Delhi",
        description: "Architect Consultant: Abid Hussain Consultant",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/camelias.jpeg',
        title: 'GDLF Camelias 616A',
        location: "Gurgaon",
        description: "Architect Consultant: Self",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/argyana.jpeg',
        title: 'Ar. Gyan P Mathur Residence',
        location: "Delhi",
        description: "Architect Consultant: In House",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/penthouse.jpeg',
        title: 'Metro City Centre - Pent House',
        location: "Lucknow",
        description: "Architect Consultant: AES",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/allahbad.jpeg',
        title: 'Allhabad Bank CP',
        location: "Delhi",
        description: "Architect Consultant: NBCC",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/up.jpeg',
        title: 'UP Cheif Minister Guest House',
        location: "Delhi",
        description: "Architect Consultant: Kothari Associates",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/louis.jpeg',
        title: 'Louis Dreyfus',
        location: "Gurgaon",
        description: "Architect Consultant: Genesis",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/apex.jpeg',
        title: 'Apex Florus',
        location: "Ghaziabad",
        description: "Architect Consultant: Sanjay Construction",
        category: 'RESIDENTIAL',

    },
    {
        image: '/assets/images/projects/eden.jpeg',
        title: 'Eden Height',
        location: "Noida",
        description: "Architect Consultant: AHCON",
        category: 'RESIDENTIAL',

    },

] 