import React from 'react'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'
import { URI } from '../../domain/constant'
import VisitorComment from './VisitorComment'
import { useSingleBlogBySlug } from "../../shared/hooks/UseBlog"
import renderHTML from "react-render-html";
import moment from 'moment';


function SingleBlog({ match }) {
    const [data] = useSingleBlogBySlug(match.params.slug);
    const { blog, blog_loading } = data;

    return (
        <div>
            <Header />
            {!blog_loading ? (
                blog && (
                    <section className="sec-space-bottom pt-70">
                        <div className="container pt-50">
                            <div className="row">
                                <div className="col-md-12 pt-15">
                                    <div className="blog-single">
                                        <div className="content">
                                            <div className="blog-media">
                                                <h4 className="sub-title-1">{moment(blog.createdAt).format('DD/MM/YYYY')}</h4>
                                                <h2 className="fsz-30"> <span className="light-font"></span> <strong>{blog.title}</strong> </h2>
                                                <img alt src={`${URI}${blog.image}`} />
                                            </div>
                                            <div className="caption">
                                                <p>
                                                    {blog.description && renderHTML(blog.description)}
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            ) : (
                <></>
            )}

            <Footer />
        </div>
    )
}

export default SingleBlog
