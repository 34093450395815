import React from 'react'
import Header from '../../components/common/Header'
import Footer from '../../components/common/Footer'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'


function ComingSoon() {
    return (
        <div>
            <Header />
            <section className="coming-wrap sec-space text-center">
                <div className="container">
                    <div className="main-logo pb-15">
                        <a href="index.html"> <img alt src="assets/img/logo/main-logo.png" /></a>
                    </div>
                    <h2 className="section-title"> <span className="light-font">coming</span> <strong>soon !</strong> </h2>
                    <p className="italic-font"> Please check back again within Some Days as We're Pretty Close </p>
                    <div className="countdown-wrapper ptb-50">
                        <div id="defaultCountdown" className="countdown" />
                    </div>
                    <h4 className="title-2">follow us on social</h4>
                    <div className="top-nav">
                        <div className="social-icon">
                            <a href="#" className="fa fa-facebook" />
                            <a href="#" className="fa fa-twitter" />
                            <a href="#" className="fa fa-google-plus" />
                            <a href="#" className="fa fa-instagram" />
                            <a href="#" className="fa fa-behance" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default ComingSoon