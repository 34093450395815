export const CertificateData = [
    {
        image: "/assets/images/certificates/certificate1.png",
        title: "Quality Management System",
    },
    {
        image: "/assets/images/certificates/certificate1.png",
        title: "Environment Management System",
    },
    {
        image: "/assets/images/certificates/11323dfa-c43f-4733-8582-a87ff2db14c4.jfif",
        title: "Quality Management System",
    },
]

