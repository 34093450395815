import React, { useState } from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import { ProjectDetails } from '../../models/projectdetail'
import { MENU_TABS } from '../../domain/constant'



function ProjectDetail() {

    const [projectdetaildata, setProjectdetaildata] = useState(ProjectDetails)

    return (
        <div>
            <Header activeTab={MENU_TABS.PROJECT} />
            <Breadcrumb title={`Project Details`} />
            <section className="Project-detail ptb-60">
                <div className="container">
                    <div className="row">
                        {
                            projectdetaildata && projectdetaildata.map((item) => {
                                return (
                                    <div className="col-md-6">
                                        <div className="Project-detail-card">
                                            <div className="Project-detail-card-title">
                                                <h4>{item.title}</h4>
                                            </div>
                                            <div className="Project-detail-card-location">
                                                <p>Location:{item.location}</p>
                                            </div>
                                            <div className="Project-detail-card-info">
                                                <h6>Architect /Consultant: {item.desc}</h6>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ProjectDetail