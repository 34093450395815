import React from 'react'
import Slider from "react-slick";
import { BrandData } from '../../models/brand';
import { useState } from 'react';


function FeaturedCompany() {
    const [brand, setBrand] = useState(BrandData)

    var settings = {
        infinite: true,
        slidesToShow: 4,
        autoplay: true,
        arrow: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <div>
            <section class="brand-area">
                <div class="container">
                    <div class="row">
                        <Slider {...settings}>
                            {
                                brand && brand.map((item) => {
                                    return (
                                        <div class="col-md-3">
                                            <div class="brand">
                                                <div class="single-item brand-images">
                                                    <a >
                                                        <img src={item.image} alt="Awesome Brand Image"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </Slider>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default FeaturedCompany