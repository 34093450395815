import React from 'react'
import { Link } from 'react-router-dom'
import { URI } from '../../domain/constant'
import renderHTML from "react-render-html";
import moment from 'moment';


function BlogCard({ blog }) {
    return (
        <>
            {blog && (
                <div className="row ptb-15">
                    <div className="col-sm-6">
                        <a href="#" className> <img alt src={`${URI}${blog.image}`} /> </a>
                    </div>
                    <div className="col-sm-6">
                        <div className="blog-caption">
                            <h4 className="sub-title-1">{moment(blog.createdAt).format('DD/MM/YYYY')}</h4>
                            <h2 className="title-2"> <a href="blog-single.html">{blog.title}</a> </h2>
                            <span className="divider-1 crl-bg" />
                            <div className="ptb-10">
                                <p>{blog.description && renderHTML(blog.description.substring(0, 220))}</p>
                            </div>
                            <Link to={`/blogs/${blog.slug}`}>
                                <a className="clr-txt"> <strong> Continue Reading <i className="fa fa-long-arrow-right" /> </strong> </a>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default BlogCard