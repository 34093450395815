import React from 'react'

function FeaturedService() {
    return (
        <div>
            <section className="services-area">
                <div className="container">
                    <div className="sec-title center text-center">
                        <h1><span>Main Services</span></h1>
                    </div>
                    <div className="row">
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-air-conditioner-1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Comfort Airconditioning</h3>
                                    <p>
                                        Comfort Airconditioning applies for the places where
                                        Airconditioning is required for comfort and Luxuries, Public
                                        places like Airport, Railway Stations.
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="1s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-construction" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Precision Air conditioning</h3>
                                    <p>
                                        Precision Air conditioning applies to the places where
                                        accurate conditions are required to carry out the process or
                                        for efficiency of the equipments & devices
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="1.5s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-write" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Industrial climate conditioning</h3>
                                    <p>
                                        Industrial Climate Conditioning applies for production floor
                                        of food processing industries, Textile Mills, Paper Mills,
                                        Explosive Filling & Processing
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="2s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-broom" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Air conditioning plant (chilled water/DX)</h3>
                                    <p>
                                        An air conditioning plant is an essential infrastructure in
                                        various industries and commercial settings, ensuring
                                        comfortable and controlled indoor environments.
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="2.5s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-hot" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Chilled water distribution system</h3>
                                    <p>
                                        A chilled water distribution system is a fundamental
                                        component of larger air conditioning plants and central
                                        cooling systems. This system is used in commercial
                                        buildings.
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="3s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-up-arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Condenser water system (cooling towers and piping)</h3>
                                    <p>
                                        A condenser water system, often used in conjunction with air
                                        conditioning plants, is a vital component in managing heat
                                        transfer and maintaining efficient cooling operations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="3s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-up-arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Ventilation System (supply/return/exhaust air fans, air washers, and
                                        ducting)</h3>
                                    <p>
                                        A ventilation system is a critical component of building
                                        services that ensures the exchange of fresh air while
                                        maintaining indoor air quality and comfort.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="3s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-up-arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Air distribution system (AHUs, FCUs, ducting, and fans)</h3>
                                    <p>
                                        An air distribution system is a crucial component of a
                                        building's HVAC (Heating, Ventilation, and Air Conditioning)
                                        system. It's responsible for delivering conditioned air to
                                        different areas within a building.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="single-item hvr-float-shadow wow fadeInUp" data-wow-delay="3s" data-wow-duration="1s" data-wow-offset={0}>
                                <div className="icon-holder">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <div className="icon-bg">
                                                <span className="flaticon-up-arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Thermal insulation of ducts and under-deck insulation</h3>
                                    <p>
                                        Thermal insulation of ducts and under-deck insulation are
                                        both important aspects of building design and HVAC (Heating,
                                        Ventilation, and Air Conditioning) systems. These insulation
                                        measures help to control heat transfer.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="special-offers text-center">
                                <h3>
                                    System Air Conditioning provides a full range of heating and air
                                    conditioning services with <span>special offers.</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FeaturedService