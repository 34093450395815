import React from 'react'
import Footer from '../../components/common/Footer'
import NewsLetter from '../../components/newsletter/NewsLetter'
import Blog from '../../components/blog/Blog'
import FeaturedCompany from '../../components/featured-company/FeaturedCompany'
import Testimonial from '../../components/testimonial/Testimonial'
import Contactus from '../../components/contact-us/Contactus'
import MainService from '../../components/main-service/MainService'
import SpecialOffer from '../../components/special-offer/SpecialOffer'
import Aboutus from '../../components/about-us/Aboutus'
import FeaturedService from '../../components/featured-service/FeaturedService'
import Banner from '../../components/banner/Banner'
import Header from '../../components/header/Header'
import Stat from '../../components/stat/Stat'
import Product from '../../components/product/Product'
import { MENU_TABS } from '../../domain/constant'

function Home() {
  return (
    <div>
      <Header activeTab={MENU_TABS.HOME} />
      <Banner />
      <MainService />
      <Aboutus />
      <Stat />
      <Product />
      <SpecialOffer />
      <FeaturedService />
      <FeaturedCompany />
      <Contactus />
      {
        // <Testimonial />
        // <Blog />
        // <NewsLetter/>
      }
      <Footer />
    </div>
  )
}

export default Home
