import React from 'react'

function Aboutus() {
  return (
    <div>
      <section className="about-us-area">
        <div className="container">
          <div className="sec-title">
            <h1><span>About Us</span></h1>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="img-holder">
                <img src="/assets/images/about/aboutPic.jpeg" alt="Awesome Image" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="middle-text-box">
                <div className="single-item top">
                  <h3>Who We Are</h3>
                  <p>
                    System AirConditioning Pvt. Ltd. stands as a leading HVAC engineering service provider, offering a comprehensive range of services and solutions to a diverse customer base. Our expertise extends across various industries, encompassing Commercial, Industrial, Educational, Hospitality, and Residential sectors.
                  </p>
                </div>
                <div className="single-item">
                  <h3>What We Do</h3>
                  <p>
                    At System AirConditioning Pvt. Ltd., we continuously strive to embrace cutting-edge technologies, cultivate innovative applications, and provide integrated solutions tailored to meet individual needs. Our commitment is not only to remain competitive but also to assemble a dynamic team of professionals dedicated to prioritizing our clients' requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="right-info-box">
                <div className="top">
                  <div className="iocn-holder">
                    <span className="flaticon-technology-1" />
                  </div>
                  <div className="text-holder">
                    <h3>24/7 Customer Care</h3>
                    <span>Call us on</span>
                  </div>
                </div>
                <div className="middle">
                  <h1><a href="tel:+91-81302-94043">813-029-4043</a></h1>

                  <p>
                    Our Experts are here to help you design your dream infrastructure with the perfect Air Conditioning and Ventilation system.
                  </p>
                  <p>
                    Contact us when you need it!
                  </p>
                </div>
                <div className="bottom about-sec-footer">
                  <h4>For queries:</h4>
                  <ul>
                    <li><span>Tel:</span><a href="tel:+011-41012538">(011) 41012538</a></li>
                    <li><span>Email:</span><a href="mail:info@systemairconditioning.com">Info@systemairconditioning.com</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Aboutus