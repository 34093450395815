import React from 'react'

function Breadcrumb({ title }) {
    return (
        <div>
            <section class="breadcrumb-area" style={{ backgroundImage: "/assets/images/slides/banner_1.jpg" }}>
                <div class="container text-center">
                    <h1>{title}</h1>
                </div>
            </section>
            <section class="breadcrumb-botton-area">
                <div class="container">
                    <div class="left">
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><i class="fa fa-caret-right" aria-hidden="true"></i></li>
                            <li class="active">{title}</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Breadcrumb;
