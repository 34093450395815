import React from 'react'
import BlogCard from './BlogCard'

function Blog() {
  return (
    <div>
      <section className="latest-blog-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sec-title">
                <h1><span>Latest News</span></h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <BlogCard />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blog
