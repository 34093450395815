export const BrandData = [
    {
        image: `/assets/images/brand/apex.jpg`,
    },
    {
        image: `/assets/images/brand/bits-pilani.jpg`,
    },
    {
        image: `/assets/images/brand/brs.png`,
    },
    {
        image: `/assets/images/brand/camelias.png`,
    },
    {
        image: `/assets/images/brand/closton.png`,
    },
    {
        image: `/assets/images/brand/EDEN-HEIGHTS-LOGO.png`,
    },
    {
        image: `/assets/images/brand/holidayinn.jpg`,
    },
    {
        image: `/assets/images/brand/italia.png`,
    },
    {
        image: `/assets/images/brand/jamia.jpeg`,
    },
    {
        image: `/assets/images/brand/kfc.png`,
    },
    {
        image: `/assets/images/brand/paras.jpg`,
    },
    {
        image: `/assets/images/brand/true.png`,
    },

]