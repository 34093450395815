import React, { useEffect } from 'react'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import Home from './containers/home/Home'
import { loadUser } from './store/actions/auth'
import setAuthToken from './domain/setAuthToken'
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes'
import PrivateRoutes from './shared/Routes/PrivateRoutes'
import PageNotFound from './containers/notfound/PageNotFound'
import Blog from './containers/blog/Blog'
import SingleBlog from './containers/blog/SingleBlog'
import ContactUs from './containers/contactus/ContactUs'
import ComingSoon from './containers/comingsoon/ComingSoon'
import Thankyou from './containers/thanyou/Thankyou'
import About from './containers/about/About'
import ComfortAirSingleService from './containers/service/ComfortAirSingleService'
import PrecisionAirSingleService from './containers/service/PrecisionAirSingleService'
import Certificates from './containers/certificates/Certificates'
import Project from './containers/project/Project'
import ProjectDetail from './containers/project/ProjectDetail'
import Service from './containers/service/Service'
import ProjectTable from './containers/project/ProjectTable'

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token')
    //then we will pass that token in setAuthToken method
    setAuthToken(token)
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/comfort-air-conditioning" component={ComfortAirSingleService} />
          <Route exact path="/precision-air-conditioning" component={PrecisionAirSingleService} />
          <Route exact path="/certificates" component={Certificates} />
          <Route exact path="/projects" component={Project} />
          <Route exact path="/project-details" component={ProjectDetail} />
          <Route exact path="/project-table" component={ProjectTable} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/about-us" component={About} />



          <Route exact path="/blogs/:slug" component={SingleBlog} />
          <Route exact path="/coming-soon" component={ComingSoon} />
          <Route exact path="/thankyou" component={Thankyou} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
