import React, { useState } from 'react'
import { statData } from '../../models/stat'

function Stat() {
    const [stat, setstat] = useState(statData)
    return (
        <div>
            <section className="stats brand-area">
                <div className="container">
                    <div className="row">
                        {
                            stat && stat.map((item) => {
                                return (
                                    <div className="col-md-3 mx-auto ">
                                        <div className="stats-card">
                                            <div className="stats-number">
                                                {item.number}
                                            </div>
                                            <div className="stats-text">
                                                <p>{item.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stat