import React from 'react'
import { Link } from 'react-router-dom';


function SpecialOffer() {
    return (
        <div>
            <section className="caption-box-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-holder pull-left">
                                <h1>We are with you, Across Seasons!</h1>
                                <p>
                                    Call an System Air Conditioning today for a quote on your new
                                    home comfort system.
                                </p>
                            </div>
                            <div className="button pull-right">
                                <Link to={`/contact-us`}>
                                    Specials &amp; Rebate
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SpecialOffer