import React from 'react'
import { Link } from "react-router-dom";


function MainService() {
    return (
        <div>
            <section className="welcome-area">
                <div className="container">
                    <div className="title text-center">
                        <h1>Welcome to System AirConditioning</h1>
                        <p>
                            With a rich legacy spanning 25 years, we take pride in being a genuine family-owned and operated business dedicated to providing top-notch heating and air conditioning solutions for residential and light commercial spaces. Trust us as your HVAC experts for unparalleled service and comfort.
                            System Air Conditioning, where expertise meets comfort.
                        </p>
                    </div>
                    <div className="row">
                        {/*Start single item*/}
                        <div className="col-md-6">
                            <div className="single-item imghvr-hinge-down">
                                <div className="inner-content">
                                    <div className="img-holder">
                                        <img src="/assets/images/services/20212.jpg" alt="Awesome Image" />
                                    </div>
                                    <div className="text-holder">
                                        <div className="icon">
                                            <span className="flaticon-car" />
                                        </div>
                                        <div className="text">
                                            <h3>COMFORT AIRCONDITIONING</h3>
                                            <span>Comfort Air Conditioning applies to places where air conditioning <br /> is required for comfort and Luxuries.</span>

                                        </div>
                                    </div>
                                </div>
                                <Link to={"/comfort-air-conditioning"}>
                                    <div className="overlay-content">
                                        <div className="text-holder">
                                            <div className="icon">
                                                <span className="flaticon-car" />
                                            </div>
                                            <div className="text">
                                                <h3>AC Services</h3>
                                                <span>COMFORT AIRCONDITIONING</span>
                                            </div>
                                        </div>
                                        <div className="bottom-text-holder">
                                            <ul className="pull-left">
                                                <li>Hotels and Resorts </li>
                                                <li>Shopping Malls</li>
                                                <li>Offices</li>
                                                <li>Healthcare Facilities</li>
                                            </ul>
                                            <div className="icon-holder">
                                                <span className="flaticon-car" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-6">
                            <div className="single-item imghvr-hinge-down">
                                <div className="inner-content">
                                    <div className="img-holder">
                                        <img src="/assets/images/services/service_23.jpg" alt="Awesome Image" />
                                    </div>
                                    <div className="text-holder">
                                        <div className="icon">
                                            <span className="flaticon-car" />
                                        </div>
                                        <div className="text">
                                            <h3>PRECISION AIR CONDITIONING</h3>
                                            <span>Precision Air Conditioning applies to the places where <br />
                                                accurate conditions are required to carry out the process.</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to={"/precision-air-conditioning"}>
                                    <div className="overlay-content">
                                        <div className="text-holder">
                                            <div className="icon">
                                                <span className="flaticon-car" />
                                            </div>
                                            <div className="text">
                                                <h3>AC Services</h3>
                                                <span>PRECISION AIR CONDITIONING</span>
                                            </div>
                                        </div>
                                        <div className="bottom-text-holder">
                                            <ul className="pull-left">
                                                <li>Hospitals and Operation Theatres</li>
                                                <li>Communication Centers and Signal Rooms</li>
                                                <li>Radar Stations</li>
                                                <li>Server Rooms</li>
                                            </ul>
                                            <div className="icon-holder">
                                                <span className="flaticon-car" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainService