import React from 'react'
import Slider from "react-slick";

function Banner() {

  var settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    arrow: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="main-slide banner-sec">
        <Slider {...settings}>
          {
            //   <div className='banner-slide' >
            //   <div className="position-relative">
            //     <div className="overlayy"></div>
            //     <div className="banner-img ">
            //       <img src="/assets/images/banner/banner_1.png" alt="" />
            //     </div>
            //   </div>
            //   <div className="banner-text position-absolute z-index-99">
            //     <div className="container">
            //       <div className="row center">
            //         <div className="col">
            //           <div className="banner-content">
            //             <h1 style={{ textAlign: 'center' }}>System Airconditioning Pvt. Ltd.</h1>
            //             <p style={{ color: 'rgb(255, 255, 255)' }}>Authorized Channel
            //               Partner of Daikin</p>
            //             <div className="d-flex-banner">
            //               <div className="banner-button mr-1rem">
            //                 <a className="btn btn-banner-lg" href="/services">Our Services</a>
            //               </div>
            //               <div className="banner-button">
            //                 <a className="btn btn-banner-blue" href="/about-us">About Us</a>
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
          }
          <div className='banner-slide' >
            <div className="position-relative">
              <div className="overlayy"></div>
              <div className="banner-img ">
                <img src="/assets/images/banner/banner_2.jpg" alt="" />
              </div>
            </div>
            <div className="banner-text position-absolute z-index-99">
              <div className="container">
                <div className="row center">
                  <div className="col">
                    <div className="banner-content">
                      <h1 style={{ textAlign: 'center' }}>System Airconditioning Pvt. Ltd.</h1>
                      <p style={{ color: 'rgb(255, 255, 255)' }}>Authorized Channel
                        Partner of Daikin</p>
                      <div className="d-flex-banner">
                        <div className="banner-button mr-1rem">
                          <a className="btn btn-banner-lg" href="/services">Our Services</a>
                        </div>
                        <div className="banner-button">
                          <a className="btn btn-banner-blue" href="/about-us">About Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </div>
  )
}

export default Banner

