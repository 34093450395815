export const statData = [
    {
        number: "1050",
        title: "SYSTEMS INSTALLED",
    },
    {
        number: "50",
        title: "HOSPITAL PROJECTS",
    },
    {
        number: "1200",
        title: "HOTEL & RESTAURENT PROJECTS",
    },
    {
        number: "500",
        title: "COMMERCIAL PROJECTS",
    },
    {
        number: "900",
        title: "INSTITUTIONAL PROJECTS",
    },
    {
        number: "129",
        title: "RESIDENTIAL PROJECTS",
    },
    {
        number: "2900",
        title: "COMPLETED PROJECTS",
    },
    {
        number: "193",
        title: "Testing Labs",
    },
]