import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import { Link } from "react-router-dom";
import { MENU_TABS } from '../../domain/constant'



function PrecisionAirSingleService() {
    return (
        <div>
            <Header activeTab={MENU_TABS.SERVICE} />
            <Breadcrumb title={`Precision Air Conditioning`} />
            <section id="service-single-area" className="smartphone-repair-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 pull-right">
                            <div className="service-single-content">
                                <div className="img-holder wow zoomInStable animated">
                                    <img src="/assets/images/services/service_23.jpg" alt="Awesome Image" />
                                </div>
                                <div className="single-service-content">
                                    <div className="single-service-content-title title mt-24">
                                        <h2>Precision Air Conditioning</h2>
                                    </div>
                                    <div className="text single-service-content-info">
                                        <p >Precision Air conditioning applies to the places where accurate conditions are required to carry out the process or for efficiency of the equipments & devices as in case of Hospitals, Operation Theatres, Communication Centres, Railway Relay & Signal Rooms, Radar Station, Server Rooms etc.</p>

                                        <h3>Precision Air Conditioning: Ensuring Optimal Environments for Critical Operations</h3>

                                        <ul className='single-service-pointer mt-5'>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Hospitals and Operation Theatres: </strong>In medical facilities, maintaining a sterile environment with controlled temperature and humidity is essential to prevent the growth of bacteria and ensure the comfort of patients and medical staff. Precision air conditioning helps in creating a clean and stable environment for surgeries and patient care.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Communication Centers and Signal Rooms: </strong>Electronic equipment, especially communication and signal equipment, can be sensitive to temperature and humidity variations. Fluctuations in these conditions can lead to malfunctions or even permanent damage to the equipment. Precision air conditioning ensures that these critical systems operate reliably.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Radar Stations: </strong> Radar systems require precise calibration to function accurately. Temperature and humidity changes can affect the performance of radar equipment. Precision air conditioning helps maintain the optimal conditions for consistent radar readings.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Server Rooms: </strong>Servers generate a significant amount of heat while processing data. Maintaining a stable temperature in server rooms is vital to prevent overheating, which could lead to data loss and equipment failure. Precision air conditioning keeps the server environment within a safe temperature range.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="border" />
                                    </div>
                                </div>
                                {/*Start middle content*/}


                                <div className="appoinment-form">
                                    <div className="title">
                                        <h2>Make an Appoinment</h2>
                                    </div>
                                    <form id="appoinment-form" action="#">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="form_name" defaultValue placeholder="Your Name*" required />
                                                <input type="email" name="form_email" defaultValue placeholder="Your Mail*" required />
                                                <input type="text" name="form_phn" defaultValue placeholder="Phone Number*" required />
                                            </div>
                                            <div className="col-md-6">
                                                <select className="selectmenu">
                                                    <option selected="selected">Select Service</option>
                                                    <option>A/C Installation</option>
                                                    <option>Maintenance &amp; Repair</option>
                                                    <option>Diagnostics</option>
                                                    <option>Cleaning &amp; Optimization</option>
                                                    <option>Heating and Water</option>
                                                    <option>Energy Efficiency</option>
                                                </select>
                                                <textarea name="form_message" placeholder="Your Message.." required defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button className="thm-btn bg-1" type="submit">Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/*End Consultation form*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12 pull-left">
                            <div className="service-single-sidebar">
                                {/*Start single sidebar*/}
                                <div className="single-sidebar">
                                    <ul className="service-lists">
                                        <li className="allservice">
                                            <Link to={`/all-services`}>View All Services <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>
                                        <li className="allservice">
                                            <Link to={`/comfort-air-conditioning`}>Comfort Air conditioning <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>
                                        <li className="allservice">
                                            <Link to={`/precision-air-conditioning`}>Precision Air conditioning <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="quick-contact-no-card">
                                <div className="quick-contact-no-heading">
                                    <h3>To Know More, <br /> Give Us a Call at</h3>
                                </div>
                                <div className="quick-contact-number blink_me">
                                    <i class="fa fa-phone-square" aria-hidden="true"></i><a href="tel:91-81302-94043">813-029-4043</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default PrecisionAirSingleService