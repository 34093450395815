import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="single-footer-widget mar-btm">
                <div className="title">
                  <img src="/assets/images/System-removebg-preview.png" alt="Awesome Footer Logo" />
                </div>
                <div className="our-info">
                  <p>Seeking HVAC services? Our experienced team offers tailored service for success in heating, ventilation, and air conditioning ventures. Partner with us today. We serve customers from all industries, including Commercial, Industrial, Educational & Hospitality Industries as well as Residential.</p>

                  <div className="button">
                    <Link to="/about-us"><span className="flaticon-arrows-1" />Read More</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pt-45">
              <div className="single-footer-widget mar-btm">
                <div className="title">
                  <h3>Usefull Links</h3>
                </div>
                <ul className="usefull-links left">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about-us">About Us</Link></li>
                  <li><Link to="/certificates">Certificates</Link></li>
                  <li><Link to="/contact-us">Contacts</Link></li>
                  <li><Link to="/services">All Services</Link></li>
                  <li><Link to="/comfort-air-conditioning">Comfort Air Conditioning</Link></li>
                  <li><Link to="/precision-air-conditioning">Precision Air Conditioning</Link></li>

                </ul>
                <ul className="usefull-links">
                  <li><Link to="/projects">Projects</Link></li>
                  <li><Link to="/">Projects Gallery</Link></li>
                  <li><Link to="/project-details">Project Details</Link></li>

                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pt-45">
              <div className="single-footer-widget martop">
                <div className="title">
                  <h3>Contact Details</h3>
                </div>
                <ul className="footer-contact-info">
                  <li>
                    <div className="icon-holder">
                      <span className="flaticon-pin map-marker" />
                    </div>
                    <div className="text-holder">
                      <h5>
                        <span>(Head-Office) Address:</span>  G 44, 3rd Floor, Kalindi Kunj Road, Shaheen Bagh, Okhla, New Delhi - 1100025.
                      </h5>
                    </div>

                  </li>
                  <li>
                    <div className="icon-holder">
                      <span className="flaticon-technology" />
                    </div>
                    <div className="text-holder footer-contact-info">
                      <h5>
                        <span>Delhi Office:</span><a href="tel=01141012538"> (011)-41012538</a>
                      </h5>
                    </div>
                    <div className="icon-holder">
                      <span className="flaticon-technology" />
                    </div>
                    <div className="text-holder">
                      <h5>
                        <span>Central No.:</span><a href="tel:9181302-94043">+91-81302-94043</a>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="icon-holder">
                      <span className="flaticon-interface" />
                    </div>
                    <div className="text-holder footer-contact-info">
                      <h5><span>Email:</span> <a href="mail:info@systemairconditioning.com">info@systemairconditioning.com</a></h5>
                    </div>
                    <div className="icon-holder">
                      <span className="flaticon-interface" />
                    </div>
                    <div className="text-holder footer-contact-info">
                      <h5><span>Email:</span><a href="mail:systemairconditioninglko@gmail.com">systemairconditioninglko@gmail.com</a></h5>
                    </div>
                  </li>
                  {
                    // <li>
                    //   <div className="icon-holder">
                    //     <span className="flaticon-clock" />
                    //   </div>
                    //   <div className="text-holder">
                    //     <h5><span>Off Hrs:</span> Mon-Satday: 9am to 18pm</h5>
                    //   </div>
                    // </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-bottom">
                <div className="copyright-text pull-left">
                  <p>
                    Copyrights © 2023 All Rights Reserved by
                    <a href="#"> System AirConditioning .</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="scroll-to-top scroll-to-target" data-target="html">
        <span className="fa fa-angle-up" />
      </div>
    </div>
  );
}

export default Footer;
