import React from 'react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Header from '../../components/header/Header'
import Footer from '../../components/common/Footer'
import { ServiceData } from '../../models/service'
import { Link } from "react-router-dom";
import { MENU_TABS } from '../../domain/constant'



function ComfortAirSingleService() {
    return (
        <div>
            <Header activeTab={MENU_TABS.SERVICE} />
            <Breadcrumb title={`Comfort Air Conditioning`} />
            <section id="service-single-area" className="smartphone-repair-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 pull-right">
                            <div className="service-single-content">
                                <div className="img-holder wow zoomInStable animated">
                                    <img src="/assets/images/services/20212.jpg" alt="Awesome Image" />
                                </div>
                                <div className="single-service-content">
                                    <div className="single-service-content-title title mt-24">
                                        <h2>Comfort Airconditioning</h2>
                                    </div>
                                    <div className="text single-service-content-info">
                                        <p >Comfort Airconditioning applies for the places where Airconditioning is required for comfort and Luxuries where the right balance of heating & cooling is essential for creating a comfortable environment as in case of hotels, Restaurants, Clubs, Shopping Malls, Residential Complex, Offices, Public places like Airport, Railway Stations. To lead a role as a progressive HVAC Company, System Airconditioning has complete range of Projects offering and vide experience suiting to various requirements of various segment.</p>

                                        <h3>Transforming Industries with Precision Industrial Climate Conditioning</h3>

                                        <ul className='single-service-pointer mt-5'>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Hotels and Resorts: </strong>Comfort air conditioning is integral to providing guests with a pleasant and relaxing stay. Proper temperature control, along with humidity regulation, contributes to a comfortable experience for travelers.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Shopping Malls: </strong> Air conditioning in shopping malls ensures that shoppers can browse and shop comfortably regardless of external weather conditions. It also encourages longer shopping durations.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Offices: </strong>Comfortable work environments lead to increased productivity. Air conditioning helps maintain a consistent and pleasant temperature for office employees, creating a conducive workspace.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <strong>Healthcare Facilities: </strong>In hospitals and medical clinics, maintaining a controlled indoor environment is crucial for patient comfort and recovery.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="border" />
                                    </div>
                                </div>

                                <div className="appoinment-form">
                                    <div className="title">
                                        <h2>Make an Appoinment</h2>
                                    </div>
                                    <form id="appoinment-form" action="#">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" name="form_name" defaultValue placeholder="Your Name*" required />
                                                <input type="email" name="form_email" defaultValue placeholder="Your Mail*" required />
                                                <input type="text" name="form_phn" defaultValue placeholder="Phone Number*" required />
                                            </div>
                                            <div className="col-md-6">
                                                <select className="selectmenu">
                                                    <option selected="selected">Select Service</option>
                                                    <option>A/C Installation</option>
                                                    <option>Maintenance &amp; Repair</option>
                                                    <option>Diagnostics</option>
                                                    <option>Cleaning &amp; Optimization</option>
                                                    <option>Heating and Water</option>
                                                    <option>Energy Efficiency</option>
                                                </select>
                                                <textarea name="form_message" placeholder="Your Message.." required defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button className="thm-btn bg-1" type="submit">Submit Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/*End Consultation form*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12 pull-left">
                            <div className="service-single-sidebar">
                                {/*Start single sidebar*/}
                                <div className="single-sidebar">
                                    <ul className="service-lists">
                                        <li className="allservice">
                                            <Link to={`/all-services`}>View All Services <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>
                                        <li className="allservice">
                                            <Link to={`/comfort-air-conditioning`}>Comfort Air conditioning <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>
                                        <li className="allservice">
                                            <Link to={`/precision-air-conditioning`}>Precision Air conditioning <i className="fa fa-cog" aria-hidden="true" /></Link>
                                        </li>

                                    </ul>
                                </div>
                                <div className="quick-contact-no-card">
                                    <div className="quick-contact-no-heading">
                                        <h3>To Know More, <br /> Give Us a Call at</h3>
                                    </div>
                                    <div className="quick-contact-number blink_me">
                                        <i class="fa fa-phone-square" aria-hidden="true"></i><a href="tel:91-81302-94043">813-029-4043</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default ComfortAirSingleService